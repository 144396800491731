// import jl_spoken_waveform_blue from './jl_spoken_waveform_blue.png';
// import jl_spoken_waveform_red from './jl_spoken_waveform_red.png';
import mr_logo from './mr_logo2.png';
// import mr_logo_trans from './mr_logo_trans2.png';

export default {
  // jl_spoken_waveform_blue,
  // jl_spoken_waveform_red,
  mr_logo
  // mr_logo_trans
};
