import React, { Component } from 'react';

class TextMarqueElement extends Component {
  get index() {
    return this.props.index;
  }

  render() {
    const { text, hidden, index } = this.props;
    const classes = ['floating-text'];
    if (!hidden) {
      classes.push('show');
    }
    return (
      <div className={classes.join(' ')} key={index}>
        {text}
      </div>
    );
  }
}

class TextMarque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      currentElement: { index: -1 }
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  get currentElement() {
    return this.state.currentElement || { index: -1 };
  }

  get elements() {
    const { text } = this.props;
    const currentElement = this.currentElement;
    const elements = text.map((v, ix) => {
      const hidden = !currentElement || currentElement.index !== ix;
      return <TextMarqueElement text={v} hidden={hidden} key={ix} index={ix} />;
    });

    return elements;
  }

  getNextElement(elements, repeat = false) {
    let currentElement = this.currentElement;
    currentElement.index++;

    if (currentElement.index >= elements.length) {
      currentElement.index = 0;
    }

    currentElement = { index: elements[currentElement.index].props.index };

    this.setState({
      currentElement: {
        index: currentElement.index
      }
    });

    if (repeat) {
      setTimeout(this.getNextElement.bind(this), 5000, this.elements, true);
    }
  }

  startTimer() {
    this.getNextElement(this.elements, true);
  }

  render() {
    return <div>{this.elements}</div>;
  }
}

export default TextMarque;
