import React, { Component } from 'react';
import './App.css';
import images from './img';
import WaveSurfer from 'wavesurfer.js';
import TextMarque from './TextMarque';

class App extends Component {
  componentDidMount() {
    // this.createWave();
  }

  createWave() {
    const options = {
      container: '#waveform',
      waveColor: '#2372e7',
      progressColor: 'purple',
      height: 300,
      interact: false,
      mediaControls: false,
      barHeight: 1
    };
    const wavesurfer = new WaveSurfer(options);
    wavesurfer.init();
    wavesurfer.on('ready', function() {
      // wavesurfer.play();
    });
    wavesurfer.load('jeremy_lorino_spoken.mp3');
  }

  render() {
    return (
      <div className="App font--righteous">
        <header className="App-header">
          <div>
            <img src={images.mr_logo} className="App-logo" alt="logo" />
          </div>
          <div
            style={{ position: 'absolute', fontSize: '2em', marginTop: '1em' }}
          >
            <TextMarque text={['WANDER', 'WONDER', 'ENGAGE']} />
          </div>
        </header>
        <section className="App-section">
          <h5 style={{ display: 'none' }}>
            The convergence of people, research and application across a range
            of disciplines
          </h5>
          <ul style={{ display: 'none' }}>
            <li>art</li>
            <li className="separator">&hellip;</li>
            <li>science</li>
            <li className="separator">&hellip;</li>
            <li>technology</li>
          </ul>
        </section>
        <footer style={{ display: 'none' }}>
          <a href="https://goo.gl/maps/2r7F3JHeTmB2">
            3100 Edloe St 335 B, Houston, TX 77027
          </a>
        </footer>
      </div>
    );
  }
}

export default App;
